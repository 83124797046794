import React from 'react';
import { PaymentCheckout } from './PaymentCheckout';
import { withChallengeData } from '../../../../contexts/ChallengeDataProvider/withChallengeData';
import {
  serverPricingToClientPriceAndCurrencyString,
  serverTimelineTypeToClientTimelineString,
} from '@wix/challenges-web-library';
import { ApplyCouponToOrderResponse } from '@wix/ambassador-challenges-v1-participant/types';

import { withLocation } from '../../../../contexts/Location/withLocation';
import { ILocationProviderProps } from '../../../../contexts/Location/LocationProvider';
import { ButtonNames } from '../../../../contexts/main/biInterfaces';

import { useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { IChallengeDataProps } from '../../../../contexts/ChallengeDataProvider/helpers';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';

export interface PaymentCheckoutContainerProps {
  onCouponApplied(coupon: ApplyCouponToOrderResponse): void;
}

type IPaymentCheckoutContainerProps = IChallengeDataProps &
  ILocationProviderProps &
  PaymentCheckoutContainerProps & { lng: string };

export const PaymentCheckoutContainer: React.FC<PaymentCheckoutContainerProps> =
  withLocation(
    withChallengeData((props: IPaymentCheckoutContainerProps) => {
      const { t } = useTranslation();
      const challenge = props.challengeData.challenge;

      const durationString = serverTimelineTypeToClientTimelineString(
        challenge.settings.timelineType as any,
        props.lng,
        t,
        'challenge-card.duration-string.ongoing',
        `challenge-card.duration-string.flexible.days_icu`,
        `challenge-card.duration-string.flexible.weeks_icu`,
        'challenge-card.duration-string.no-limit',
      );

      const bi = useBi();

      return (
        <PaymentCheckout
          showOneAppInfo={async () => {
            await bi.report(
              memberWebAppButtonClickV2({
                buttonName: ButtonNames.OneAppInfo,
              }),
            );

            props.goToExternalUrl('https://www.wix.com/mobile/wix-app');
          }}
          title={
            // @ts-expect-error
            challenge.mocked
              ? t(challenge.settings.description.title)
              : challenge.settings.description.title
          }
          price={serverPricingToClientPriceAndCurrencyString(
            challenge.settings.pricing as any,
          )}
          duration={durationString}
          onCouponApplied={props.onCouponApplied}
        />
      );
    }),
  );
